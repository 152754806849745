import { reaction } from 'mobx';
import { TABLE_PER_PAGE } from './constants';

export type TableData<T> = {
	pagination: {
		page: number;
		current: number;
		total: number;
	};
	dataSource: T[];
	loading: boolean;
	sorter: { order?: string; field: string };
	setPage: (page: number) => void;
	setSorter: (sorter: { order?: string; field: string }) => void;
	fetch: () => Promise<void>;
};

export const TABLE_ORDER = {
	ascend: 'ASC',
	descend: 'DESC',
};

export const createTableDataDefault = <T,>() => {
	return {
		loading: false,
		pagination: {
			page: 1,
			total: 0,
		},
		sorter: {},
		dataSource: [],
	} as unknown as TableData<T>;
};

export const createTableData = (store, query) => {
	const tableData: TableData<unknown> = store.tableData;

	const fetchData = async () => {
		tableData.loading = true;

		const params = {
			offset: (tableData.pagination.page - 1) * TABLE_PER_PAGE,
			field: tableData.sorter.field,
			direction: tableData.sorter.order && TABLE_ORDER[tableData.sorter.order],
		};

		try {
			const response = await query(params);

			tableData.dataSource = response.items;
			tableData.pagination.total = response.total;
		} catch (error) {
			console.log('error: ', error);
		} finally {
			tableData.loading = false;
		}
	};

	reaction(() => tableData.pagination.page, fetchData);

	const setPage = (page = 1) => {
		tableData.pagination.page = page;
		tableData.pagination.current = page;
	};

	const setSorter = (sorter) => {
		tableData.sorter = sorter;
		if (tableData.pagination.page === 1) fetchData();
		else tableData.setPage(1);
	};

	tableData.fetch = fetchData;
	tableData.setPage = setPage;
	tableData.setSorter = setSorter;
};
