import { Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import AppTable from '../../../../components/tables/AppTable/AppTable';
import { usersStoreContext } from '../../../../stores/UsersStore';
import Actions from './Actions';

const getColumns = () => [
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
		sorter: true,
	},
	{
		title: 'Country',
		dataIndex: 'country',
		key: 'country',
		sorter: true,
	},
	{
		title: 'Created at',
		dataIndex: 'createdAt',
		key: 'createdAt',
		sorter: true,
	},
	{
		title: 'Paid count',
		key: 'balance.paidAttemptsCount',
		sorter: true,
		render: ({ balance }) => {
			return balance?.paidAttemptsCount || 0;
		},
	},
	{
		title: 'Free attempts',
		key: 'balance.freeAttemptsCount',
		sorter: true,
		render: ({ balance }) => {
			return balance?.freeAttemptsCount || 0;
		},
	},
	{
		title: 'Role',
		dataIndex: 'role',
		key: 'role',
		sorter: true,
		render: (role) => {
			return <Tag color={'magenta'}>{role}</Tag>;
		},
	},
	{
		title: 'Actions',
		render: ({ id, isBlocked }) => <Actions id={id} isBlocked={isBlocked} />,
	},
	{
		title: 'Last IP',
		dataIndex: 'lastIp',
		key: 'lastIp',
		sorter: true,
	}
];

interface Props {}

const UsersTable = (props: Props) => {
	const { tableData } = useContext(usersStoreContext);

	useEffect(() => {
		tableData.fetch();
	}, []);

	const handleTableChange = (pagination, filters, sorter) => {
		if (!sorter?.field) {
			sorter.field = sorter.columnKey;
		}
		tableData.setPage(pagination.current);
		tableData.setSorter(sorter);
	};

	return (
		<AppTable
			key={tableData.pagination.total + '' + tableData.pagination.page}
			onChange={handleTableChange}
			pagination={{ ...tableData.pagination }}
			columns={getColumns()}
			dataSource={tableData.dataSource}
			loading={tableData.loading}
		/>
	);
};

export default observer(UsersTable);
