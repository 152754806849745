import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useContext, useState } from 'react';
import { UsersApi } from '../../../services/api/Users';
import { usersStoreContext } from '../../../stores/UsersStore';
import { userStoreContext } from '../../../stores/UserStore';
import { Roles } from '../../../utils/constants';
import { countriesList } from '../../../utils/countries';
import AppSelect from '../../../components/core/AppSelect';

interface Props {}

const AddUserModal = (props: Props) => {
	const { user } = useContext(userStoreContext);
	const { tableData } = useContext(usersStoreContext);
	const [isModalVisible, setIsModalVisible] = useState(false);


	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onFinish = (values) => {

		UsersApi.create(values).then(() => {
			setIsModalVisible(false);

			if (tableData.pagination.page !== 1) tableData.setPage(1);
			else tableData.fetch();
		});
	};

	return (
		<>
			<Button type="primary" onClick={showModal}>
				Add user
			</Button>

			<Modal footer={null} title="Add new user" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>

				<Form initialValues={{ role: 'user' }} layout="vertical" onFinish={onFinish} autoComplete="off">

					<Form.Item
						label="Email"
						name="email"
						rules={[{ required: true, message: 'Please input your Email!' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label="Password"
						name="password"
						rules={[{ required: true, message: 'Please input your password!' }]}
					>
						<Input.Password />
					</Form.Item>

					<Form.Item
						label="Country"
						name="country"
						rules={[{ required: true, message: 'Please input your country!' }]}
					>
						<Select defaultValue={''}>
							{
								countriesList.map((country) => <Select.Option value={country}>{country}</Select.Option>)}
							}
						</Select>
					</Form.Item>

					{user?.role === Roles.admin && (
						<Form.Item
							label="Role"
							name="role"
							rules={[{ required: true, message: 'Please select role!' }]}
						>
							<Select defaultValue={'user'}>
								<Select.Option value="admin">Admin</Select.Option>
								<Select.Option value="user">User</Select.Option>
							</Select>
						</Form.Item>
					)}

					<Form.Item>
						<Button type="primary" htmlType="submit">
							Create user
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default AddUserModal;
