import { Space } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import withPrivateRoute from '../../hocs/withPrivateRoute';
import AddUserModal from '../../modules/Admin/Users/AddUserModal';
import UsersTable from '../../modules/Admin/Users/UsersTable/UsersTable';
import { Roles } from '../../utils/constants';

interface Props {}

const Users = (props: Props) => {
	return (
		<>
			<Helmet>
				<title>Users</title>
			</Helmet>
			<div className="page">
				<h1 className="page__title">Users</h1>
				<Space style={{ marginBottom: 16 }}>
					<AddUserModal />
				</Space>
				<UsersTable />
			</div>
		</>
	);
};

export default withPrivateRoute(Users, [Roles.admin]);
