import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import React, { useContext, useState } from 'react';
import { usersStore } from '../../../../stores/UsersStore';
import { userStoreContext } from '../../../../stores/UserStore';

const Actions = ({ id, isBlocked }) => {
	const { user } = useContext(userStoreContext);
	const [loading, setLoading] = useState(false);

	if (user?.id === id) return null;

	const blockUser = () => {
		setLoading(true);
		usersStore.blockUsers([id]).finally(() => setLoading(false));
	};

	const unblockUser = () => {
		setLoading(true);
		usersStore.unblockUsers([id]).finally(() => setLoading(false));
	};

	return (
		<Space>
			<Tooltip title="Unblock user">
				<Button
					onClick={unblockUser}
					disabled={!isBlocked}
					type="primary"
					icon={<CheckCircleOutlined />}
					loading={loading}
				></Button>
			</Tooltip>
			<Tooltip title="Block user">
				<Button
					onClick={blockUser}
					disabled={isBlocked}
					type="text"
					danger
					icon={<CloseCircleOutlined />}
					loading={loading}
				></Button>
			</Tooltip>
		</Space>
	);
};

export default Actions;
